import { addGamesToCollections, removeGamesFromCollections } from '@/api/games';
import { ScheduleItem } from '@/pages/TabPages/SchedulePage/components/Content/components/ScheduleItem';
import { Collection } from '@/shared/prisma';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { Card, Cell, Checkbox, Spinner } from '@telegram-apps/telegram-ui';
import { FC, useEffect, useState } from 'react';

import styles from './index.module.css';

type Props = {
  collection: Collection;
  item: ScheduleGame;
  defaultChecked?: boolean;
};

export const CheckedCollectionGameItem: FC<Props> = ({
  collection,
  item,
  defaultChecked,
}) => {
  const [checkedLocal, setCheckedLocal] = useState(!!defaultChecked);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setCheckedLocal(!!defaultChecked);
  }, [defaultChecked, collection]);

  const handleCheckedChange = async () => {
    setIsUpdating(true);
    try {
      const newValue = !checkedLocal;
      if (newValue) {
        await addGamesToCollections({
          gameIds: [item.id],
          collectionIds: [collection.id],
        });
      } else {
        await removeGamesFromCollections({
          gameIds: [item.id],
          collectionIds: [collection.id],
        });
      }
      setCheckedLocal(newValue);
    } catch (error) {
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Card
      key={item.id}
      onChange={!isUpdating ? handleCheckedChange : undefined}
    >
      <ScheduleItem
        item={item}
        onClick={!isUpdating ? handleCheckedChange : undefined}
        options={{
          isShowCover: false,
          isShowActions: false,
          isShowOrganizerAndPlace: false,
          isShowScheduledDateTime: false,
          isShowDescription: !checkedLocal,
        }}
      />
      <Cell
        Component="label"
        subhead="Игра добавлена"
        className={styles.cell}
        multiline
        readOnly
      >
        {getHumanDateTime(item.createdAt)}
      </Cell>
      <Cell
        Component="label"
        before={
          !isUpdating ? (
            <Checkbox checked={checkedLocal} />
          ) : (
            <Spinner size="s" className={styles.spinner} />
          )
        }
        className={styles.cell}
        multiline
      >
        Размещение в подборке «{collection.title}»
      </Cell>
    </Card>
  );
};
