import { Space } from '@/components/Space';
import { Caption } from '@telegram-apps/telegram-ui';
import { FC } from 'react';

import styles from './index.module.css';

type Props = {
  icon?: JSX.Element;
  text?: string;
};

export const MediaInfo: FC<Props> = ({ icon, text }) => {
  if (!icon && !text) {
    return null;
  }
  return (
    <Space className={styles.wrapper} size={10}>
      {icon}
      {text && <Caption className={styles.caption}>{text}</Caption>}
    </Space>
  );
};
