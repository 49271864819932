import { haptic } from '@/helpers/feedback';
import useUserFavorites from '@/stores/useUserFavorites';
import { FC, useMemo } from 'react';
import { BiHeart, BiSolidHeart } from 'react-icons/bi';
import { CircleButton, CircleButtonProps } from '../CircleButton';

import styles from './index.module.css';

type Props = {
  gameId: string;
  circleButtonProps?: Omit<CircleButtonProps, 'onClick'>;
};

export const CircleFavoriteButton: FC<Props> = ({
  gameId,
  circleButtonProps,
}) => {
  const {
    favoriteGameIds: favoriteGames,
    addToFavorites,
    removeFromFavorites,
  } = useUserFavorites();
  const isFavorite = useMemo(() => {
    return favoriteGames.indexOf(gameId) !== -1;
  }, [gameId, favoriteGames]);
  const handleAddToFavorites = () => {
    haptic();
    addToFavorites(gameId);
  };
  const handleRemoveFromFavorites = () => {
    haptic();
    removeFromFavorites(gameId);
  };
  return (
    <CircleButton
      {...circleButtonProps}
      onClick={isFavorite ? handleRemoveFromFavorites : handleAddToFavorites}
    >
      {isFavorite ? (
        <BiSolidHeart className={styles.red} size={24} />
      ) : (
        <BiHeart size={24} />
      )}
    </CircleButton>
  );
};
