import { organizerDisconnect } from '@/api/organizer';
import { PageTitle } from '@/components/PageTitle';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import useOrganizerStore from '@/stores/useOrganizerStore';
import { initMainButton, initPopup } from '@telegram-apps/sdk-react';
import { Section } from '@telegram-apps/telegram-ui';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { AdminItem } from './components/AdminItem';
import { ShareTokenModal } from './components/ShareTokenModal';

import styles from './index.module.css';

export const OrganizerOnCityAdminPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationItem = location.state.item as AdminOrganizerOnCity;

  const { refreshListOrganizer, listOrganizer } = useOrganizerStore();
  const [isShareTokenOpen, setIsShareTokenOpen] = useState(false);

  const item = listOrganizer.find((v) => v.id === locationItem.id);

  useEffect(() => {
    if (location.pathname !== '/organizer/admin/') {
      return;
    }
    if (isShareTokenOpen) {
      return;
    }
    const [mainButton] = initMainButton();
    mainButton
      .setText('Добавить администратора')
      .enable()
      .show()
      .on('click', handleAddUserClick);
    return () => {
      mainButton.disable().hide().off('click', handleAddUserClick);
    };
  }, [location.pathname, isShareTokenOpen]);

  useEffect(() => {
    if (item) {
      return;
    }
    navigate(-2);
  }, [item, navigate]);

  if (!item) {
    return null;
  }

  const { OrganizerOnCity } = item;
  const { Organizer, City, Admins } = OrganizerOnCity;
  const subTitle = getCityTile(City);

  const handleAddUserClick = () => {
    setIsShareTokenOpen(true);
  };

  const handleShareTokenModalOpenChange = (isOpen: boolean) => {
    setIsShareTokenOpen(isOpen);
  };

  const handleDisconnectUser = async (userId: string) => {
    const popup = initPopup();
    const answer = await popup.open({
      title: 'Удалить администратора?',
      message:
        'Пользователь не будет иметь доступа к управлению, вы уверены что хотите удалить администратора?',
      buttons: [
        { id: 'delete', type: 'destructive', text: 'Удалить' },
        { type: 'cancel' },
      ],
    });
    if (answer !== 'delete') {
      return;
    }
    try {
      await organizerDisconnect({
        organizerOnCityId: OrganizerOnCity.id,
        userId,
      });
      await refreshListOrganizer();
    } catch (error) {
      await popup.open({
        title: 'Ошибка',
        message:
          'Не удалось удалить администратора, попробуйте повторить позже',
        buttons: [{ type: 'default', text: 'OK' }],
      });
      return;
    }
  };

  return (
    <>
      <div className={styles.padding}>
        <PageTitle title={Organizer.title} subTitle={subTitle} />
        <div className={styles.content}>
          <Section header="Список администраторов">
            {Admins.map((admin) => (
              <AdminItem
                key={admin.id}
                item={admin}
                onClick={() => handleDisconnectUser(admin.User.id)}
              />
            ))}
          </Section>
        </div>
      </div>
      <ShareTokenModal
        organizerOnCityId={item.organizerOnCityId}
        subject={`${Organizer.title} (${subTitle})`}
        isOpen={isShareTokenOpen}
        onOpenChange={handleShareTokenModalOpenChange}
      />
    </>
  );
};
