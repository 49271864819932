import { InputSection } from '@/components/Inputs/InputSection';
import { PageTitle } from '@/components/PageTitle';
import { usePlatform } from '@/hooks/usePlatform';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import {
  ButtonCell,
  IconContainer,
  Input,
  List,
} from '@telegram-apps/telegram-ui';
import { useState } from 'react';
import { BiLogoTelegram } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { getCityTile } from '../../../utils';

import styles from './index.module.css';

export const OrganizerOnCityProfilePage = () => {
  const platform = usePlatform();
  const location = useLocation();
  const item = location.state.item as AdminOrganizerOnCity;

  const { OrganizerOnCity } = item;
  const { Organizer, City } = OrganizerOnCity;
  const subTitle = getCityTile(City);

  const [formData, setFormData] = useState({
    title: Organizer.title,
    site: Organizer.site ?? '',
    citySite: OrganizerOnCity.site ?? '',
  });

  const updateFomData = (code: string, value: string) => {
    let valueSet: string | null = value;
    if (value.trim() === '') {
      valueSet = null;
    }
    setFormData({ ...formData, [code]: valueSet });
  };

  const handleConnectToAuthor = () => {
    window.open('https://t.me/rigerous');
  };

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        <List
          className={platform === 'ios' ? styles.listIos : styles.listAndroid}
        >
          <InputSection header="Общая информация" />
          <Input
            placeholder="Название квиза"
            value={formData.title}
            onChange={(e) => updateFomData('title', e.target.value)}
            readOnly
          />
          <Input
            placeholder="Адрес сайта"
            value={formData.site}
            onChange={(e) => updateFomData('site', e.target.value)}
            readOnly
          />
          <InputSection header={`Информация для ${City.name}`} />
          <Input
            placeholder="Адрес сайта"
            value={formData.citySite}
            onChange={(e) => updateFomData('citySite', e.target.value)}
            readOnly
          />
          <InputSection header="Как изменить данные?" />
          <ButtonCell
            before={
              <IconContainer>
                <BiLogoTelegram size={24} />
              </IconContainer>
            }
            onClick={handleConnectToAuthor}
          >
            Отправить сообщение
          </ButtonCell>
          <InputSection footer="Вы можете изменить данные об игре, отправив сообщение разработчику" />
        </List>
      </div>
    </div>
  );
};
