import quiz_placeholder from '@/assets/quiz-placeholder.jpg';
import { Space } from '@/components/Space';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { Card, Chip, Headline, Text, Title } from '@telegram-apps/telegram-ui';
import moment from 'moment-timezone';
import { useMemo } from 'react';
import { BiSolidGame, BiSolidMap } from 'react-icons/bi';
import { Actions } from './components/Actions';
import { Availability } from './components/Availability';
import { CoverActions } from './components/CoverActions';
import { CoverImage } from './components/CoverImage';

import styles from './index.module.css';

type ChipEntity = {
  label: string;
  backgroundColor: string;
  color: string;
  icon: React.ReactNode;
};

type ScheduleItemOptions = {
  isShowCover?: boolean;
  isShowActions?: boolean;
  isShowOrganizerAndPlace?: boolean;
  isShowScheduledDateTime?: boolean;
  isShowDescription?: boolean;
};

type Props = {
  item: ScheduleGame;
  onClick?: () => void;
  options?: ScheduleItemOptions;
};

export const ScheduleItem: React.FC<Props> = ({
  item,
  onClick,
  options = {},
}) => {
  const {
    title,
    description,
    scheduledAt,
    Organizer,
    place,
    imageUrl,
    archivedAt,
    availability,
  } = item;

  const {
    isShowCover = true,
    isShowActions = true,
    isShowOrganizerAndPlace = true,
    isShowScheduledDateTime = true,
    isShowDescription = true,
  } = options;

  const quizTitle = Organizer?.title;

  const scheduledAtRender = useMemo(() => {
    const date = getHumanDateTime(scheduledAt);
    if (moment().tz('Europe/Moscow').isSame(scheduledAt, 'day')) {
      return {
        date,
        className: styles.scheduledAtToday,
      };
    }
    if (moment().tz('Europe/Moscow').add(1, 'day').isSame(scheduledAt, 'day')) {
      return {
        date,
        className: styles.scheduledAtTomorrow,
      };
    }
    return {
      date,
      className: styles.scheduledAt,
    };
  }, [scheduledAt]);

  const chips = useMemo(() => {
    const backgroundColor = '#FF000090';
    const color = '#000';
    const r: ChipEntity[] = [];
    if (quizTitle) {
      r.push({
        label: quizTitle,
        backgroundColor,
        color,
        icon: <BiSolidGame size={20} />,
      });
    }
    if (place) {
      r.push({
        label: place,
        backgroundColor,
        color,
        icon: <BiSolidMap size={20} />,
      });
    }
    return r;
  }, [quizTitle, place]);

  return (
    <Card className={styles.card} onClick={onClick}>
      {isShowCover && (
        <div className={styles.imageAndTagsWrapper}>
          <CoverImage url={imageUrl ?? quiz_placeholder} height="30vh" />
          <div className={styles.overImageWrapper}>
            {!archivedAt && <CoverActions scheduleGame={item} />}
          </div>
        </div>
      )}
      <div className={styles.content}>
        <Space direction="vertical" size={8}>
          <Title level="2" weight="1" className={styles.title}>
            {title}
          </Title>
          {isShowScheduledDateTime && (
            <Headline className={scheduledAtRender.className}>
              {scheduledAtRender.date}
            </Headline>
          )}
          {isShowOrganizerAndPlace && chips.length > 0 && (
            <div className={styles.chips}>
              {chips.map(({ label, icon }, idx) => (
                <Chip
                  key={idx}
                  mode="elevated"
                  readOnly
                  className={styles.chip}
                >
                  {icon}
                  {label}
                </Chip>
              ))}
            </div>
          )}
          {isShowDescription && description && <Text>{description}</Text>}
          {!archivedAt && <Availability value={availability} />}
          {isShowActions && (
            <Actions item={item} isRegistrationAvailable={!archivedAt} />
          )}
        </Space>
      </div>
    </Card>
  );
};
