import { Chip } from '@telegram-apps/telegram-ui';
import { FC } from 'react';

import styles from './index.module.css';

type Props = {
  items: string[];
  selected?: string;
  onSelect: (organizer?: string) => void;
  icon?: JSX.Element;
  iconAll?: JSX.Element;
};

export const ChipSelector: FC<Props> = ({
  items,
  selected,
  onSelect,
  icon,
  iconAll,
}) => {
  return (
    <div className={styles.wrapper}>
      <Chip
        className={styles.chip}
        onClick={() => onSelect()}
        mode={selected === undefined ? 'elevated' : 'mono'}
      >
        {iconAll ?? icon}
        Все
      </Chip>
      {items.map((item, idx) => (
        <Chip
          key={idx}
          className={styles.chip}
          onClick={() => onSelect(item)}
          mode={selected === item ? 'elevated' : 'mono'}
        >
          {icon}
          {item}
        </Chip>
      ))}
    </div>
  );
};
