import { getInitDataRaw } from '@/helpers/auth';
import { AuthType } from '@/shared/services/http/auth';
import {
  GetLinkPreviewBody,
  GetLinkPreviewResponse,
} from '@/shared/services/http/linkPreview';
import request from 'umi-request';
import { API } from '../../const/env';

export const getLinkPreview = async (data: GetLinkPreviewBody) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GetLinkPreviewResponse>(`${API}/linkPreview/get`, {
    method: 'POST',
    headers: {
      authorization,
    },
    data,
  });
};
