import { API } from '@/const/env';
import { getInitDataRaw } from '@/helpers/auth';
import { AuthType } from '@/shared/services/http/auth';
import {
  AddGamesToCollectionsBody,
  AddGamesToCollectionsResponse,
  CollectionsListResponse,
  RemoveGamesFromCollectionsBody,
  RemoveGamesFromCollectionsResponse,
  UpdateGameCollectionsBody,
  UpdateGameCollectionsResponse,
} from '@/shared/services/http/game';
import { GetScheduleListResponse } from '@/shared/services/http/schedule';
import request from 'umi-request';

export const getCollectionsList = async () => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<CollectionsListResponse>(`${API}/game/collections/list`, {
    method: 'GET',
    headers: {
      authorization,
    },
  });
};

export const getGamesListCollections = async () => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GetScheduleListResponse>(`${API}/game/collections/games`, {
    method: 'GET',
    headers: {
      authorization,
    },
  });
};

export const updateGameCollections = async (
  data: UpdateGameCollectionsBody
) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<UpdateGameCollectionsResponse>(
    `${API}/game/collections/update`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};

export const addGamesToCollections = async (
  data: AddGamesToCollectionsBody
) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<AddGamesToCollectionsResponse>(
    `${API}/game/collections/add`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};

export const removeGamesFromCollections = async (
  data: RemoveGamesFromCollectionsBody
) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<RemoveGamesFromCollectionsResponse>(
    `${API}/game/collections/remove`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};
