import { GameAvailability } from '@/shared/prisma';
import { FC, useMemo } from 'react';

import { classNames } from '@telegram-apps/sdk-react';
import { Caption } from '@telegram-apps/telegram-ui';
import styles from './index.module.css';

type Props = {
  value?: GameAvailability | null;
};

export const Availability: FC<Props> = ({ value }) => {
  const entity = useMemo(() => {
    if (!value) {
      return;
    }
    switch (value) {
      case 'Available':
        return undefined;
      // return {
      //   className: 'green',
      //   text: 'Регистрация открыта',
      // };
      case 'ToReserve':
        return {
          className: 'orange',
          text: 'Регистрация в резерв',
        };
      case 'NotAvailable':
        return {
          className: 'red',
          text: 'Регистрация закрыта',
        };
      case 'NotOpened':
        return {
          className: 'gray',
          text: 'Регистрация еще не открыта',
        };
    }
  }, [value]);
  if (!entity) {
    return null;
  }
  return (
    <div className={styles.wrapper}>
      <div className={classNames(styles.circle, styles[entity.className])} />
      <Caption>{entity.text}</Caption>
    </div>
  );
};
