import { retrieveLaunchParams } from '@telegram-apps/sdk-react';

export const getInitDataRaw = () => {
  try {
    const { initDataRaw = '' } = retrieveLaunchParams();
    if (!initDataRaw) {
      return;
    }
    return initDataRaw;
  } catch {
    return;
  }
};
