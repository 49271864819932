import { usePlatform } from '@/hooks/usePlatform';
import { classNames } from '@telegram-apps/sdk-react';
import { IconContainer, Subheadline, Text } from '@telegram-apps/telegram-ui';
import {
  FormInput,
  FormPublicProps,
} from '@telegram-apps/telegram-ui/dist/components/Form/FormInput/FormInput';
import moment from 'moment';
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { changeTimeInDateTime, getTimeStrFromDateTime } from './utils';

import styles from './index.module.css';

interface HtmlFormPublicProps
  extends FormPublicProps,
    InputHTMLAttributes<HTMLInputElement> {}

type Props = Omit<HtmlFormPublicProps, 'after' | 'value' | 'onChange'> & {
  value?: Date | null;
  onChange?: (value: Date | null) => void;
};

export const TimeSelect: FC<Props> = ({
  header,
  before,
  status,
  className,
  disabled,
  value,
  onChange,
  ...rest
}) => {
  const platform = usePlatform();
  const TypographyComponent = platform === 'ios' ? Text : Subheadline;
  const [valueLocal, setValueLocal] = useState(value);
  useEffect(() => {
    setValueLocal(value);
  }, [value]);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const time = e.target.value;
    if (time) {
      const newDateTime = changeTimeInDateTime(
        time,
        value ? value : moment().toDate()
      );
      onChange?.(newDateTime);
      setValueLocal(newDateTime);
    } else {
      onChange?.(null);
      setValueLocal(null);
    }
  };
  return (
    <FormInput
      header={header}
      before={before}
      status={status}
      disabled={disabled}
      className={classNames(
        styles.wrapper,
        platform === 'ios' && styles['wrapper--ios'],
        className
      )}
      after={
        !rest.readOnly && (
          <IconContainer>
            <BiTimeFive aria-hidden size={20} />
          </IconContainer>
        )
      }
    >
      <TypographyComponent
        Component="input"
        className={styles.input}
        type={!rest.readOnly ? 'time' : undefined}
        disabled={disabled}
        value={valueLocal ? getTimeStrFromDateTime(valueLocal) : ''}
        onChange={handleOnChange}
        {...rest}
      />
    </FormInput>
  );
};
