import type { ComponentType, JSX } from 'react';
import { BiCalendar, BiEdit, BiHeart, BiInfoCircle } from 'react-icons/bi';
import { AboutPage } from './pages/TabPages/AboutPage';
import { FavoritesPage } from './pages/TabPages/FavoritesPage';
import { OrganizerPage } from './pages/TabPages/OrganizerPage';
import { OrganizerOnCityAdminPage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCityAdminPage';
import { OrganizerOnCityGamePage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCityGamePage';
import { OrganizerOnCityGamesCollectionPage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCityGamesCollectionPage';
import { OrganizerOnCityGamesPage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCityGamesPage';
import { OrganizerOnCityIndexPage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCityIndexPage';
import { OrganizerOnCityProfilePage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCityProfilePage';
import { OrganizerOnCitySourcePage } from './pages/TabPages/OrganizerPage/components/Pages/OrganizerOnCitySourcePage';
import { SchedulePage } from './pages/TabPages/SchedulePage';
import { ViewGamePage } from './pages/TabPages/SchedulePage/components/Pages/ViewGamePage';

type Route = {
  path: string;
  Component: ComponentType;
  title?: string;
  icon?: JSX.Element;
};

type RouteTabBarItem = {
  isInMenuBar?: boolean;
  isOrganizerTab?: boolean;
};

type RouteItem = Route & RouteTabBarItem;

export const routes: RouteItem[] = [
  {
    path: '/',
    Component: SchedulePage,
    title: 'Расписание',
    icon: <BiCalendar size={24} />,
    isInMenuBar: true,
  },
  {
    path: '/favorites/',
    Component: FavoritesPage,
    title: 'Избранное',
    icon: <BiHeart size={24} />,
    isInMenuBar: true,
  },
  {
    path: '/organizer/',
    Component: OrganizerPage,
    title: 'Администрирование',
    icon: <BiEdit size={24} />,
    isOrganizerTab: true,
    isInMenuBar: true,
  },
  {
    path: '/game/',
    Component: ViewGamePage,
  },
  {
    path: '/organizer/index/',
    Component: OrganizerOnCityIndexPage,
  },
  {
    path: '/organizer/profile/',
    Component: OrganizerOnCityProfilePage,
  },
  {
    path: '/organizer/admin/',
    Component: OrganizerOnCityAdminPage,
  },
  {
    path: '/organizer/source/',
    Component: OrganizerOnCitySourcePage,
  },
  {
    path: '/organizer/games/',
    Component: OrganizerOnCityGamesPage,
  },
  { path: '/organizer/game/', Component: OrganizerOnCityGamePage },
  {
    path: '/organizer/games-collections/',
    Component: OrganizerOnCityGamesCollectionPage,
  },
  {
    path: '/about/',
    Component: AboutPage,
    title: 'О сервисе',
    icon: <BiInfoCircle size={24} />,
    isInMenuBar: true,
  },
];
