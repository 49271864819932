import { Title } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { GamesLine } from './components/GamesLine';
import { GamesInCollection } from './types';

type Props = {
  gamesInCollection: GamesInCollection[];
};

export const ScheduleGamesInCollections: FC<Props> = ({
  gamesInCollection,
}) => {
  const navigate = useNavigate();
  const handleOnGameClick = (
    linkSlug: string,
    gamesInCollection: GamesInCollection
  ) => {
    navigate('/game/', { state: { linkSlug, gamesInCollection } });
  };
  return (
    <>
      {gamesInCollection.map(({ collection, list }, idx) => (
        <div key={idx}>
          <Title>{collection.title}</Title>
          <br />
          <GamesLine
            scheduleGames={list}
            onGameClick={(linkSlug) =>
              handleOnGameClick(linkSlug, { collection, list })
            }
          />
        </div>
      ))}
    </>
  );
};
