import { Collection } from '@/shared/prisma';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { List } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { CheckedCollectionGameItem } from './components/CheckedCollectionGameItem';

import styles from './index.module.css';

type Props = {
  collection: Collection;
  games: ScheduleGame[];
  checked: string[];
};

export const CheckedScheduleCollectionList: FC<Props> = ({
  collection,
  games,
  checked,
}) => {
  return (
    <List className={styles.list}>
      {games.map((item) => {
        const defaultChecked = checked.indexOf(item.id) !== -1;
        return (
          <CheckedCollectionGameItem
            key={item.id}
            collection={collection}
            item={item}
            defaultChecked={defaultChecked}
          />
        );
      })}
    </List>
  );
};
