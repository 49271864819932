import { getListOrganizer } from '@/api/organizer';
import { ListOrganizerResponse } from '@/shared/services/http/organizer';
import { create } from 'zustand';

type OrganizerStore = {
  listOrganizer: ListOrganizerResponse;
  refreshListOrganizer: () => Promise<void>;
};

const useOrganizerStore = create<OrganizerStore>((set) => ({
  listOrganizer: [],
  refreshListOrganizer: async () => {
    const listOrganizer = await getListOrganizer();
    set(() => ({ listOrganizer }));
  },
}));

export default useOrganizerStore;
