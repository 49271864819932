import { isValidHttpUrl } from '@/components/Inputs/LinkInput/utils';
import { GameCreate } from '@/shared/services/http/organizer';
import moment from 'moment';

export const dateParse = (value: Date | string | null | undefined) => {
  if (!value) {
    return undefined;
  }
  return moment(value).toDate();
};

export const validateAndGetGameCreateData = (
  validateFormData: Partial<GameCreate>
): GameCreate | string => {
  if (!validateFormData.title) {
    return 'Введите Название игры.';
  }
  if (!validateFormData.scheduledAt) {
    return 'Введите Дату и Время игры.';
  }
  if (moment(validateFormData.scheduledAt) < moment()) {
    return 'Дата и Время игры не может быть в прошлом';
  }
  if (
    validateFormData.registerLink &&
    !isValidHttpUrl(validateFormData.registerLink)
  ) {
    return 'Введите корректную ссылку на веб-страницу с расписанием или формой регистрации';
  }
  if (validateFormData.imageUrl && !isValidHttpUrl(validateFormData.imageUrl)) {
    return 'Введите корректную ссылку на изображение для обложки игры';
  }
  return {
    ...validateFormData,
    title: validateFormData.title,
    scheduledAt: validateFormData.scheduledAt,
  };
};
