import { usePlatform } from '@/hooks/usePlatform';
import { classNames } from '@telegram-apps/sdk-react';
import { Subheadline, Text } from '@telegram-apps/telegram-ui';
import {
  FormInput,
  FormPublicProps,
} from '@telegram-apps/telegram-ui/dist/components/Form/FormInput/FormInput';
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { LinkPreview } from './components/LinkPreview';
import { GetLinkPreviewResponse } from '@/shared/services/http/linkPreview';

import styles from './index.module.css';

interface HtmlFormPublicProps
  extends FormPublicProps,
    InputHTMLAttributes<HTMLInputElement> {}

type Props = Omit<HtmlFormPublicProps, 'value' | 'onChange'> & {
  value?: string | null;
  onChange?: (string: string | null) => void;
  onPreview?: (preview: GetLinkPreviewResponse) => void;
  hideInput?: boolean;
  mediaTypes?: string[];
  hintText?: string;
  errorText?: string;
};

export const LinkInput: FC<Props> = ({
  header,
  before,
  after,
  status,
  className,
  disabled,
  value,
  onChange,
  onPreview,
  hideInput,
  mediaTypes,
  hintText,
  errorText,
  ...rest
}) => {
  const platform = usePlatform();
  const TypographyComponent = platform === 'ios' ? Text : Subheadline;
  const [valueLocal, setValueLocal] = useState(value);
  useEffect(() => {
    setValueLocal(value);
  }, [value]);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue) {
      onChange?.(inputValue);
      setValueLocal(inputValue);
    } else {
      onChange?.(null);
      setValueLocal(null);
    }
  };
  if (hideInput && !valueLocal) {
    return null;
  }
  return (
    <FormInput
      header={header}
      before={before}
      after={after}
      status={status}
      disabled={disabled}
      className={classNames(
        styles.wrapper,
        platform === 'ios' && styles['wrapper--ios'],
        className
      )}
    >
      <div className={styles.inputWrapper}>
        {!hideInput && (
          <TypographyComponent
            Component="textarea"
            className={styles.input}
            disabled={disabled}
            value={valueLocal ? valueLocal : ''}
            onChange={handleOnChange}
            {...rest}
          />
        )}
        <LinkPreview
          text={valueLocal}
          mediaTypes={mediaTypes}
          onPreview={onPreview}
          hintText={hintText}
          errorText={errorText}
        />
      </div>
    </FormInput>
  );
};
