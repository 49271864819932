import { getSchedule } from '@/api/schedule';
import { PageTitle } from '@/components/PageTitle';
import { DEFAULT_CITY } from '@/shared/services/http/consts';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { Button, List, Spinner, Text, Title } from '@telegram-apps/telegram-ui';
import _, { sortBy } from 'lodash';
import { FC, useEffect, useMemo, useState } from 'react';
import { BiGame, BiSolidGame } from 'react-icons/bi';
import { ChipSelector } from './components/ChipSelector';
import { ScheduleGamesInCollections } from './components/ScheduleGamesInCollections';
import { GamesInCollection } from './components/ScheduleGamesInCollections/types';
import { ScheduleGamesInPromo } from './components/ScheduleGamesInPromo';
import { ScheduleItem } from './components/ScheduleItem';

import styles from './index.module.css';

type Props = {
  isOnlyFavorites?: boolean;
};

const TITLE = 'Расписание';
const SUB_TITLE = DEFAULT_CITY.name;

export const Content: FC<Props> = ({ isOnlyFavorites }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<ScheduleGame[]>();

  const [organizerSelected, setOrganizerSelected] = useState<string>();

  const getData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      // TODO: При реализации выбора городов, устанавливать тут город
      const r = await getSchedule({
        ...DEFAULT_CITY,
        isOnlyFavorites: isOnlyFavorites ? '1' : '0',
      });
      setList(sortBy(r, (v) => v.scheduledAt));
    } catch (error) {
      setIsError(true);
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const collections = useMemo(() => {
    if (!list) {
      return;
    }
    let gamesInCollection: GamesInCollection[] = [];
    let gamesNotInCollection: ScheduleGame[] = [...list];
    for (const item of list) {
      for (const collection of item.Collections) {
        const found = gamesInCollection.find(
          (v) => v.collection.id === collection.id
        );
        if (found) {
          found.list.push(item);
        } else {
          gamesInCollection.push({
            collection,
            list: [item],
          });
        }
      }
    }
    gamesInCollection = gamesInCollection.filter((v) => v.list.length >= 2);
    gamesInCollection = _.sortBy(
      gamesInCollection,
      (v) => v.collection.orderIndex
    );
    for (const group of gamesInCollection) {
      for (const item of group.list) {
        gamesNotInCollection = gamesNotInCollection.filter(
          (v) => v.id !== item.id
        );
      }
    }
    return { gamesInCollection, gamesNotInCollection };
  }, [list]);

  const organizerList = useMemo(() => {
    if (!list) {
      return;
    }
    const r = [];
    for (const item of list) {
      const { Organizer: o } = item;
      if (!o) {
        continue;
      }
      r.push(o.title);
    }
    return _.sortBy(_.uniq(r));
  }, [list]);

  const filteredList = useMemo(() => {
    if (!list) {
      return;
    }
    let r = list;
    if (organizerSelected) {
      r = r.filter(
        (v) => v.Organizer && v.Organizer.title === organizerSelected
      );
    }
    return r;
  }, [list, organizerSelected]);

  const handleRefresh = () => {
    getData();
  };

  if (isLoading) {
    return (
      <List className={styles.padding}>
        <PageTitle title={TITLE} subTitle={SUB_TITLE} />
        <Spinner size="s" className={styles.loading} />
      </List>
    );
  }

  if (isError) {
    return (
      <List className={styles.padding}>
        <PageTitle title={TITLE} subTitle={SUB_TITLE} />
        <div className={styles.emptyWrapper}>
          <Text className={styles.empty}>
            Не удалось загрузить расписание игр, попробуйте зайти позже
          </Text>
          <Button onClick={handleRefresh}>Попробовать еще раз</Button>
        </div>
      </List>
    );
  }

  const handleClearFilter = () => {
    setOrganizerSelected(undefined);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleOrganizerSelect = (organizer: string | undefined) => {
    setOrganizerSelected(organizer);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (filteredList === undefined) {
    return null;
  }

  const isShowPromoAndCollections = !isOnlyFavorites && !organizerSelected;

  return (
    <List className={styles.padding}>
      <PageTitle title={TITLE} subTitle={SUB_TITLE} />
      <div className={styles.blockWrapper}>
        {!isOnlyFavorites && organizerList && (
          <ChipSelector
            items={organizerList}
            selected={organizerSelected}
            onSelect={handleOrganizerSelect}
            icon={<BiSolidGame size={20} />}
            iconAll={<BiGame size={20} />}
          />
        )}
        {isShowPromoAndCollections && collections && (
          <>
            <ScheduleGamesInPromo
              scheduleGames={collections.gamesNotInCollection}
            />
            <ScheduleGamesInCollections
              gamesInCollection={collections.gamesInCollection}
            />
          </>
        )}
        {filteredList.length === 0 && (
          <div className={styles.emptyWrapper}>
            <Text className={styles.empty}>Игр не найдено.</Text>
          </div>
        )}
        {filteredList.length > 0 && (
          <div>
            <Title>{organizerSelected ?? 'Все игры'}</Title>
            <br />
            <div className={styles.blockWrapper}>
              {filteredList.map((item) => (
                <ScheduleItem key={item.id} item={item} />
              ))}
            </div>
          </div>
        )}
        {organizerSelected && (
          <div className={styles.clearFilterWrapper}>
            <Button mode="bezeled" onClick={handleClearFilter}>
              Сбросить фильтр
            </Button>
          </div>
        )}
      </div>
    </List>
  );
};
