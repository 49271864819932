import { TextInput } from '@/components/Inputs/TextInput';
import { Space } from '@/components/Space';
import { Chip, InputProps } from '@telegram-apps/telegram-ui';
import { FC, useMemo } from 'react';
import { FormInput } from '@telegram-apps/telegram-ui/dist/components/Form/FormInput/FormInput';

import styles from './index.module.css';

type Props = Omit<InputProps, 'value' | 'onChange' | 'list'> & {
  list: string[];
  value: string | undefined;
  onChange: (value: string) => void;
};

export const InputWithList: FC<Props> = ({
  list,
  value,
  onChange,
  ...rest
}) => {
  const filteredList = useMemo(() => {
    if (!value) {
      return list;
    }
    const found = list.find(v => v === value);
    if (found) {
      return [];
    }
    const filtered = list.filter(
      (v) => v.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
    return filtered;
  }, [list, value]);
  const handleChange = (newValue: string) => {
    onChange(newValue);
  };
  return (
    <>
      <TextInput
        {...rest}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
      {!rest.readOnly && filteredList.length > 0 && (
        <FormInput className={styles.formInputWrapper}>
          <Space className={styles.items}>
            {filteredList.map((v, idx) => (
              <Chip key={idx} onClick={() => handleChange(v)}>
                {v}
              </Chip>
            ))}
          </Space>
        </FormInput>
      )}
    </>
  );
};
