import { gamesListOrganizer } from '@/api/organizer';
import { PageTitle } from '@/components/PageTitle';
import { Game } from '@/shared/prisma';
import {
  AdminOrganizerOnCity,
  StatOrganizerResponse,
} from '@/shared/services/http/organizer';
import useUiStore from '@/stores/useUiStore';
import { initMainButton } from '@telegram-apps/sdk-react';
import { Section, Spinner, TabsList, Text } from '@telegram-apps/telegram-ui';
import { TabsItem } from '@telegram-apps/telegram-ui/dist/components/Navigation/TabsList/components/TabsItem/TabsItem';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { GameItem } from './components/GameItem';

import styles from './index.module.css';

export const OrganizerOnCityGamesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state.item as AdminOrganizerOnCity;
  const isCanEdit = location.state.isCanEdit as boolean;
  const stat = location.state.stat as StatOrganizerResponse;

  const { selectedGamesTab, setSelectedGamesTab } = useUiStore();
  const [games, setGames] = useState<Game[]>();

  const { OrganizerOnCity } = item;
  const { Organizer, City, GameSource } = OrganizerOnCity;

  const filteredGames = useMemo(() => {
    if (!games) {
      return undefined;
    }
    return games.filter(
      (game) => game.isDraft === (selectedGamesTab === 'drafts')
    );
  }, [games, selectedGamesTab]);

  const gamesStat = useMemo(() => {
    if (!games) {
      return undefined;
    }
    const published = games.filter((game) => game.isDraft === false).length;
    const drafts = games.filter((game) => game.isDraft === true).length;
    return { published, drafts };
  }, [games]);

  const refreshGames = async (gameSourceId: string) => {
    try {
      setGames(await gamesListOrganizer({ gameSourceId }));
    } catch (error) {
      console.log('Cannot load games', error);
    }
  };

  useEffect(() => {
    refreshGames(GameSource.id);
  }, [GameSource.id]);

  useEffect(() => {
    if (location.pathname !== '/organizer/games/') {
      return;
    }
    if (!isCanEdit) {
      return;
    }
    const [mainButton] = initMainButton();
    mainButton
      .setText('Добавить игру')
      .enable()
      .show()
      .on('click', handleAddGameClick);
    return () => {
      mainButton.disable().hide().off('click', handleAddGameClick);
    };
  }, [location.pathname, isCanEdit]);

  const handleAddGameClick = () => {
    navigate('/organizer/game/', { state: { item, isCanEdit, stat } });
  };

  const handleGameClick = (game: Game) => {
    navigate('/organizer/game/', {
      state: { item, game, isCanEdit, stat },
    });
  };

  const subTitle = getCityTile(City);

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        <TabsList className={styles.tabsList}>
          <TabsItem
            selected={selectedGamesTab === 'published'}
            onClick={() => setSelectedGamesTab('published')}
          >
            Опубликованы
            {gamesStat && (
              <small className={styles.counts}>{gamesStat.published}</small>
            )}
          </TabsItem>
          <TabsItem
            selected={selectedGamesTab === 'drafts'}
            onClick={() => setSelectedGamesTab('drafts')}
          >
            Черновики
            {gamesStat && (
              <small className={styles.counts}>{gamesStat.drafts}</small>
            )}
          </TabsItem>
        </TabsList>
        {filteredGames === undefined && (
          <div className={styles.emptyWrapper}>
            <Spinner className={styles.empty} size="s" />
          </div>
        )}
        {filteredGames?.length === 0 && (
          <div className={styles.emptyWrapper}>
            <Text className={styles.empty}>Игр в расписание не добавлено.</Text>
          </div>
        )}
        {filteredGames && filteredGames.length > 0 && (
          <Section>
            {filteredGames.map((game) => (
              <GameItem
                key={game.id}
                item={game}
                onClick={() => handleGameClick(game)}
              />
            ))}
          </Section>
        )}
      </div>
    </div>
  );
};
