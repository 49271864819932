import { Game } from '@/shared/prisma';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { Cell, IconContainer } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { BiCalendarEvent } from 'react-icons/bi';

type Props = {
  item: Game;
  onClick?: () => void;
};

export const GameItem: FC<Props> = ({ item, onClick }) => {
  const { scheduledAt, title, place } = item;
  return (
    <Cell
      onClick={onClick}
      before={
        <IconContainer>
          <BiCalendarEvent size={24} />
        </IconContainer>
      }
      subhead={getHumanDateTime(scheduledAt)}
      subtitle={place}
      multiline
    >
      {title}
    </Cell>
  );
};
