import { getInitDataRaw } from '@/helpers/auth';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { FC } from 'react';
import { CircleFavoriteButton } from './components/CircleFavoriteButton';

type Props = {
  scheduleGame: ScheduleGame;
};

export const CoverActions: FC<Props> = ({ scheduleGame }) => {
  const initDataRaw = getInitDataRaw();
  if (!initDataRaw) {
    return null;
  }
  const { id } = scheduleGame;
  return <CircleFavoriteButton gameId={id} />;
};
