import { create } from 'zustand';

export type SelectedGamesTab = 'published' | 'drafts';

type CitiesStore = {
  selectedGamesTab: SelectedGamesTab;
  setSelectedGamesTab: (gamesTab: SelectedGamesTab) => void;
};

const useUiStore = create<CitiesStore>((set) => ({
  selectedGamesTab: 'published',
  setSelectedGamesTab: (gamesTab: SelectedGamesTab) => {
    set(() => ({ selectedGamesTab: gamesTab }));
  },
}));

export default useUiStore;
