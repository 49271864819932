import { usePlatform } from '@/hooks/usePlatform';
import { classNames } from '@telegram-apps/sdk-react';
import { Text } from '@telegram-apps/telegram-ui';
import {
  FormInput,
  FormPublicProps,
} from '@telegram-apps/telegram-ui/dist/components/Form/FormInput/FormInput';
import { FC, InputHTMLAttributes, useEffect, useState } from 'react';

import styles from './index.module.css';

interface HtmlFormPublicProps
  extends FormPublicProps,
    InputHTMLAttributes<HTMLInputElement> {}

type Props = Omit<HtmlFormPublicProps, 'list' | 'value' | 'onChange'> & {
  list: string[];
  value?: string | null;
  onChange?: (string: string | null) => void;
};

export const ImageSelector: FC<Props> = ({
  header,
  before,
  after,
  status,
  className,
  disabled,
  list,
  value,
  onChange,
}) => {
  const platform = usePlatform();
  const [valueLocal, setValueLocal] = useState(value);
  useEffect(() => {
    setValueLocal(value);
  }, [value]);
  const handleSelectImage = (item: string) => {
    onChange?.(item);
    setValueLocal(item);
  };
  return (
    <FormInput
      header={header}
      before={before}
      after={after}
      status={status}
      disabled={disabled}
      className={classNames(
        styles.wrapper,
        platform === 'ios' && styles['wrapper--ios'],
        className
      )}
    >
      <Text className={styles.title}>
        Использовать изображение по ссылке регистрации в качестве обложки?
      </Text>
      <div className={styles.inputWrapper}>
        {list.map((item, idx) => (
          <img
            onClick={() => handleSelectImage(item)}
            alt="Обложка"
            key={idx}
            src={item}
            className={classNames(
              styles.image,
              valueLocal === item && styles.selectedImage
            )}
          />
        ))}
      </div>
    </FormInput>
  );
};
