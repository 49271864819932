import {
  LinkAppPreview,
  LinkAudioPreview,
  LinkImagePreview,
  LinkPagePreview,
  LinkVideoPreview,
} from '@/shared/services/http/linkPreview';
import { FC } from 'react';
import { SiteName } from './components/SiteName';

import styles from './index.module.css';

type Props = {
  media:
    | LinkPagePreview
    | LinkImagePreview
    | LinkAudioPreview
    | LinkVideoPreview
    | LinkAppPreview;
};

export const MediaView: FC<Props> = ({ media }) => {
  if ('title' in media) {
    return <SiteName title={media.title} faviconUrl={media.favicons?.[0]} />;
  }
  switch (media.mediaType) {
    case 'image': {
      return (
        <img
          alt="Изображение"
          src={media.url}
          className={styles.image}
        />
      );
    }
  }
  return null;
};
