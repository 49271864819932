import { FC, useEffect, useState } from 'react';
import { DateSelect } from '../DateSelect';
import { TimeSelect } from '../TimeSelect';

type Props = {
  value: Date | null | undefined;
  onChange: (value: Date | null) => void;
  readOnly?: boolean;
  defaultTime?: string;
};

export const GameDateTimeSelect: FC<Props> = ({
  value,
  onChange,
  readOnly,
  defaultTime,
}) => {
  const [valueLocal, setValueLocal] = useState(value);

  useEffect(() => {
    if (value === valueLocal) {
      return;
    }
    setValueLocal(value);
  }, [value]);

  const handleOnChange = (newValue: Date | null) => {
    setValueLocal(newValue);
    if (value !== newValue) {
      onChange(newValue);
    }
  };

  return (
    <>
      <DateSelect
        value={value}
        onChange={(v) => handleOnChange(v)}
        readOnly={readOnly}
        placeholder="Дата *"
        defaultTime={defaultTime}
      />
      {value && (
        <>
          <TimeSelect
            value={value}
            onChange={(v) => handleOnChange(v)}
            readOnly={readOnly}
            placeholder="Время"
          />
        </>
      )}
    </>
  );
};
