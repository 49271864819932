import { Space } from '@/components/Space';
import { Avatar, Caption } from '@telegram-apps/telegram-ui';
import { FC } from 'react';

import styles from './index.module.css';

type Props = {
  name?: string;
  title: string;
  faviconUrl?: string;
};

export const SiteName: FC<Props> = ({ name, faviconUrl, title }) => {
  const acronym = title?.[0] ?? 'A';
  return (
    <Space className={styles.wrapper} size={10}>
      {faviconUrl && <Avatar size={20} src={faviconUrl} acronym={acronym} />}
      {name && <Caption weight="1">{name}</Caption>}
      <Caption>{title}</Caption>
    </Space>
  );
};
