import {
  gameCreateOrganizer,
  gameDeleteOrganizer,
  gameUpdateOrganizer,
} from '@/api/organizer';
import { CostInput } from '@/components/Inputs/CostInput';
import { GameDateTimeSelect } from '@/components/Inputs/GameDateTimeSelect';
import { ImageSelector } from '@/components/Inputs/ImageSelector';
import { InputSection } from '@/components/Inputs/InputSection';
import { InputWithList } from '@/components/Inputs/InputWithList';
import { LinkInput } from '@/components/Inputs/LinkInput';
import { TextInput } from '@/components/Inputs/TextInput';
import { PageTitle } from '@/components/PageTitle';
import { usePlatform } from '@/hooks/usePlatform';
import { Game } from '@/shared/prisma';
import { GetLinkPreviewResponse } from '@/shared/services/http/linkPreview';
import {
  AdminOrganizerOnCity,
  StatOrganizerResponse,
} from '@/shared/services/http/organizer';
import useGameStore, { GameData } from '@/stores/useGameStore';
import { initMainButton, initPopup } from '@telegram-apps/sdk-react';
import {
  ButtonCell,
  Cell,
  IconContainer,
  List,
  Section,
  Switch,
  Textarea,
} from '@telegram-apps/telegram-ui';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { DraftStatusIcon } from '../OrganizerOnCityGamesPage/components/GameItem/DraftStatusIcon';
import { dateParse, validateAndGetGameCreateData } from './utils';

import styles from './index.module.css';

const PATH_NAME = '/organizer/game/';

export const OrganizerOnCityGamePage = () => {
  const platform = usePlatform();
  const location = useLocation();
  const navigate = useNavigate();

  const [isUpdateState, updateState] = useState({});
  const forceUpdate = useCallback(() => updateState({}), []);

  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { gameData, setGameData, updateField, clearGameData } = useGameStore(
    (state) => state
  );

  const locationItem = location.state.item as AdminOrganizerOnCity;
  const locationGame = location.state.game as Game | undefined;
  const locationIsCanEdit = location.state.isCanEdit as boolean | undefined;
  const { lastTime, places = [] } = location.state
    .stat as StatOrganizerResponse;

  const isNewGame = !locationGame;
  const isManualSource =
    locationItem.OrganizerOnCity.GameSource.type === 'Manual';

  const [registerLinkPreview, setRegisterLinkPreview] =
    useState<GetLinkPreviewResponse>();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const previewImages =
    typeof registerLinkPreview === 'object' && 'images' in registerLinkPreview
      ? registerLinkPreview.images
      : undefined;

  const isPublished = locationGame ? !locationGame.isDraft : false;
  const [isPublish, setIsPublish] = useState(isPublished);

  useEffect(() => {
    setGameData(locationGame ?? {});
  }, [locationGame]);

  useEffect(() => {
    if (location.pathname !== PATH_NAME) {
      return;
    }
    return () => {
      clearGameData();
    };
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname !== PATH_NAME) {
      return;
    }
    if (!locationIsCanEdit) {
      return;
    }
    const [mainButton] = initMainButton();
    mainButton.enable().show();
    return () => {
      mainButton.hideLoader().disable().hide();
    };
  }, [location.pathname, locationIsCanEdit]);

  useEffect(() => {
    if (location.pathname !== PATH_NAME) {
      return;
    }
    if (!locationIsCanEdit) {
      return;
    }
    const title = isPublished
      ? 'Сохранить'
      : isPublish
      ? 'Сохранить и опубликовать'
      : 'Сохранить как черновик';
    const [mainButton] = initMainButton();
    if (isButtonLoading) {
      mainButton.enable().show().showLoader().setText(title);
    } else {
      mainButton.enable().show().hideLoader().setText(title);
    }
    forceUpdate();
  }, [isPublished, isPublish, isUpdateState, isButtonLoading]);

  const handleSaveClick = useCallback(async () => {
    if (!gameData) {
      return;
    }
    const validateResult = validateAndGetGameCreateData(gameData);
    const popup = initPopup();
    if (typeof validateResult === 'string') {
      await popup.open({
        title: 'Ошибка',
        message: validateResult,
        buttons: [{ type: 'default', text: 'OK' }],
      });
      return;
    }
    setIsButtonLoading(true);
    try {
      if (isNewGame) {
        await gameCreateOrganizer({
          gameSourceId: locationItem.OrganizerOnCity.gameSourceId,
          Game: {
            ...validateResult,
            isDraft: !isPublish,
          },
        });
      } else {
        await gameUpdateOrganizer({
          id: locationGame.id,
          ...validateResult,
          isDraft: !isPublish,
        });
      }
      navigate(-1);
    } catch (error) {
      await popup.open({
        title: 'Ошибка',
        message: 'Не удалось сохранить игру, попробуйте повторить позже',
        buttons: [{ type: 'default', text: 'OK' }],
      });
      console.error('Error saving', validateResult, error);
    } finally {
      setIsButtonLoading(false);
    }
  }, [gameData, isPublish, isNewGame]);

  useEffect(() => {
    const [mainButton] = initMainButton();
    mainButton.on('click', handleSaveClick);
    return () => {
      mainButton.off('click', handleSaveClick);
    };
  }, [handleSaveClick]);

  const { OrganizerOnCity } = locationItem;
  const { Organizer, City } = OrganizerOnCity;

  const getGameValue = (key: keyof GameData) => {
    if (!gameData) {
      return '';
    }
    const value = gameData[key];
    if (!value) {
      return '';
    }
    return value.toString();
  };

  const handleImageChange = async (value: string | null) => {
    setSelectedImage(value);
    await updateField('imageUrl', value);
  };

  const handleChangeIsPublish = (
    e: React.MouseEvent | React.ChangeEvent,
    value: boolean
  ) => {
    e.preventDefault();
    setIsPublish(value);
  };

  const handleDelete = async () => {
    if (!locationGame) {
      return;
    }
    const popup = initPopup();
    const answer = await popup.open({
      message: 'Переместить игру в архив?',
      buttons: [
        { id: 'delete', type: 'destructive', text: 'Переместить в архив' },
        { type: 'cancel' },
      ],
    });
    if (answer === 'delete') {
      setIsButtonLoading(true);
      try {
        await gameDeleteOrganizer({
          gameId: locationGame.id,
        });
        navigate(-1);
      } catch (error) {
        await popup.open({
          title: 'Ошибка',
          message:
            'Не удалось переместить игру в архив, попробуйте повторить позже',
          buttons: [{ type: 'default', text: 'OK' }],
        });
        console.error('Error archiving', error);
      } finally {
        setIsButtonLoading(false);
      }
    }
  };

  const subTitle = getCityTile(City);
  const isShowSwitch = !locationGame || locationGame.isDraft;

  if (!gameData) {
    return null;
  }

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        <List
          className={platform === 'ios' ? styles.listIos : styles.listAndroid}
        >
          {locationGame && (
            <>
              <InputSection header="Статус" />
              <Cell
                readOnly
                before={
                  <IconContainer>
                    <DraftStatusIcon isDraft={locationGame.isDraft} />
                  </IconContainer>
                }
              >
                {locationGame.isDraft ? 'Черновик' : 'Опубликована'}
              </Cell>
            </>
          )}
          <InputSection header="Об игре" />
          <TextInput
            placeholder="Название *"
            // value={getGameValue('title')}
            value={gameData.title}
            onChange={async (e) => await updateField('title', e.target.value)}
            readOnly={!locationIsCanEdit}
          />
          <Textarea
            placeholder="Описание"
            value={getGameValue('description')}
            onChange={async (e) =>
              await updateField('description', e.target.value)
            }
            readOnly={!locationIsCanEdit}
          />
          <CostInput
            placeholder="Цена"
            value={getGameValue('cost')}
            // onChange={(value) => updateFormNumericField('cost', value)}
            onChange={async (value) => await updateField('cost', value)}
            readOnly={!locationIsCanEdit}
          />
          <InputSection header="Дата, время и место" />
          <GameDateTimeSelect
            value={dateParse(getGameValue('scheduledAt'))}
            onChange={async (value) => await updateField('scheduledAt', value)}
            readOnly={!locationIsCanEdit}
            defaultTime={lastTime}
          />
          <InputWithList
            placeholder="Место"
            list={places}
            value={getGameValue('place')}
            onChange={async (value) => await updateField('place', value)}
            readOnly={!locationIsCanEdit}
          />
          <InputSection header="Ссылки" />
          <LinkInput
            placeholder="Ссылка на веб-страницу с расписанием или формой регистрации"
            value={getGameValue('registerLink')}
            onChange={async (value) => await updateField('registerLink', value)}
            onPreview={setRegisterLinkPreview}
            readOnly={!locationIsCanEdit}
            hintText={
              locationIsCanEdit
                ? 'Вставьте ссылку на веб-страницу с расписанием или с формой регистрации'
                : undefined
            }
            errorText={
              locationIsCanEdit
                ? 'Ссылка должна вести на веб-страницу с расписанием или с формой регистрации'
                : undefined
            }
          />
          {locationIsCanEdit &&
            previewImages &&
            previewImages.length > 0 &&
            !getGameValue('imageUrl') && (
              <>
                <ImageSelector
                  list={previewImages}
                  value={selectedImage}
                  onChange={handleImageChange}
                />
              </>
            )}
          <LinkInput
            mediaTypes={['photo', 'image']}
            placeholder="Ссылка на обложку"
            value={getGameValue('imageUrl')}
            onChange={handleImageChange}
            readOnly={!locationIsCanEdit}
            hintText={
              locationIsCanEdit
                ? 'Вставьте ссылку на изображение для обложки игры'
                : undefined
            }
            errorText={
              locationIsCanEdit
                ? 'Ссылка должна вести на изображение для обложки игры'
                : undefined
            }
          />
          {isShowSwitch && (
            <>
              <InputSection header="Публикация" />
              <Section
                footer={
                  isPublish
                    ? 'Игра будет опубликована в Расписании и будут разосланы уведомления'
                    : 'Игра будет сохранена как Черновик, она не будет опубликована в Расписании, вы сможете опубликовать ее позже'
                }
              >
                <Cell
                  onClick={(e) => handleChangeIsPublish(e, !isPublish)}
                  after={
                    <Switch
                      checked={isPublish}
                      onChange={(e) => handleChangeIsPublish(e, !isPublish)}
                    />
                  }
                >
                  Опубликовать
                </Cell>
              </Section>
            </>
          )}
          {locationGame && isManualSource && (
            <Section>
              <ButtonCell onClick={handleDelete} mode="destructive">
                Переместить игру в архив
              </ButtonCell>
            </Section>
          )}
        </List>
      </div>
    </div>
  );
};
