import { getSchedule } from '@/api/schedule';
import { addToFavorites, removeFromFavorites } from '@/api/user';
import { DEFAULT_CITY } from '@/shared/services/http/consts';
import _ from 'lodash';
import { create } from 'zustand';

export type SelectedGamesTab = 'published' | 'drafts';

type UserFavorites = {
  favoriteGameIds: string[];
  refreshFavoriteGames: () => Promise<void>;
  addToFavorites: (gameId: string) => Promise<boolean>;
  removeFromFavorites: (gameId: string) => Promise<boolean>;
};

const useUserFavorites = create<UserFavorites>((set) => ({
  favoriteGameIds: [],
  refreshFavoriteGames: async () => {
    const favoriteGames = await getSchedule({
      ...DEFAULT_CITY,
      isOnlyFavorites: '1',
    });
    set(() => ({ favoriteGameIds: favoriteGames.map((v) => v.id) }));
  },
  addToFavorites: async (gameId: string) => {
    // TODO: Если ошибка, нужно отменять
    set((prev) => ({
      favoriteGameIds: _.uniq([...prev.favoriteGameIds, gameId]),
    }));
    ///
    try {
      const r = await addToFavorites({ gameId });
      if (!r) {
        throw new Error();
      }
      return true;
    } catch {
      return false;
    }
  },
  removeFromFavorites: async (gameId: string) => {
    // TODO: Если ошибка, нужно отменять
    set((prev) => ({
      favoriteGameIds: _.uniq(prev.favoriteGameIds.filter((v) => v !== gameId)),
    }));
    ///
    try {
      const r = await removeFromFavorites({ gameId });
      if (!r) {
        throw new Error();
      }
      return true;
    } catch {
      return false;
    }
  },
}));

export default useUserFavorites;
