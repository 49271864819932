import { usePlatform } from '@/hooks/usePlatform';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { classNames } from '@telegram-apps/sdk-react';
import {
  IconContainer,
  Subheadline,
  Text,
  VisuallyHidden,
} from '@telegram-apps/telegram-ui';
import {
  FormInput,
  FormPublicProps,
} from '@telegram-apps/telegram-ui/dist/components/Form/FormInput/FormInput';
import moment from 'moment';
import {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react';
import { BiCalendar } from 'react-icons/bi';
import { changeDateInDateTime, getDateStrFromDateTime } from './utils';

import styles from './index.module.css';

interface HtmlFormPublicProps
  extends FormPublicProps,
    InputHTMLAttributes<HTMLInputElement> {}

type Props = Omit<HtmlFormPublicProps, 'after' | 'value' | 'onChange'> & {
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  defaultTime?: string;
};

export const DateSelect: FC<Props> = ({
  header,
  before,
  status,
  className,
  value,
  onChange,
  placeholder,
  defaultTime,
  ...rest
}) => {
  const platform = usePlatform();
  const TypographyComponent = platform === 'ios' ? Text : Subheadline;
  const [valueLocal, setValueLocal] = useState(value);
  useEffect(() => {
    setValueLocal(value);
  }, [value]);
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    if (date) {
      const time = defaultTime || '19:00';
      const newDateTime = changeDateInDateTime(
        date,
        value ? value : moment(moment().format(`YYYY-MM-DD ${time}`)).toDate()
      );
      onChange?.(newDateTime);
      setValueLocal(newDateTime);
    } else {
      onChange?.(null);
      setValueLocal(null);
    }
  };
  return (
    <FormInput
      header={header}
      before={before}
      status={status}
      className={classNames(
        styles.wrapper,
        platform === 'ios' && styles['wrapper--ios'],
        className
      )}
      after={
        !rest.readOnly ? (
          <>
            <VisuallyHidden
              Component="input"
              type="date"
              onChange={handleOnChange}
              value={valueLocal ? getDateStrFromDateTime(valueLocal) : ''}
              className={styles.input}
              {...rest}
            />
            <IconContainer>
              <BiCalendar aria-hidden size={20} />
            </IconContainer>
          </>
        ) : undefined
      }
    >
      <TypographyComponent
        className={classNames(
          styles.text,
          !valueLocal && styles['placeholder']
        )}
      >
        {valueLocal
          ? getHumanDateTime(valueLocal, { isWithTime: false })
          : placeholder}
      </TypographyComponent>
    </FormInput>
  );
};
