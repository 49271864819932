import { getInitDataRaw } from '@/helpers/auth';
import { AuthType } from '@/shared/services/http/auth';
import {
  GetScheduleItemParams,
  GetScheduleItemResponse,
  GetScheduleListQuery,
  GetScheduleListResponse,
} from '@/shared/services/http/schedule';
import request from 'umi-request';
import { API } from '../../const/env';

export const getSchedule = async (params: GetScheduleListQuery) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GetScheduleListResponse>(`${API}/schedule`, {
    method: 'GET',
    headers: {
      authorization,
    },
    params,
  });
};

export const getScheduleItem = async (params: GetScheduleItemParams) => {
  const { linkSlug } = params;
  return await request<GetScheduleItemResponse>(`${API}/schedule/${linkSlug}`, {
    method: 'GET',
  });
};
