import { PageTitle } from '@/components/PageTitle';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import useOrganizerStore from '@/stores/useOrganizerStore';
import { Cell, IconContainer, List, Section } from '@telegram-apps/telegram-ui';
import { BiCollection } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { OrganizerOnCityItem } from './components/OrganizerOnCityItem';

import styles from './index.module.css';

export const Content = () => {
  const { listOrganizer } = useOrganizerStore();
  const navigate = useNavigate();

  const handleOnClick = (item: AdminOrganizerOnCity) => {
    navigate('/organizer/index/', {
      state: {
        item,
      },
    });
  };

  const handleEditCollections = () => {
    navigate('/organizer/games-collections/');
  };

  return (
    <List className={styles.padding}>
      <PageTitle title="Администрирование" />
      <Section className={styles.content}>
        {listOrganizer.map((item) => (
          <OrganizerOnCityItem
            key={item.id}
            item={item}
            onClick={() => handleOnClick(item)}
          />
        ))}
      </Section>
      <Section className={styles.content}>
        <Cell
          onClick={handleEditCollections}
          before={
            <IconContainer>
              <BiCollection size={24} />
            </IconContainer>
          }
        >
          Распределение игр по подборкам
        </Cell>
      </Section>
    </List>
  );
};
