import {
  InlineButtonsItem,
  InlineButtonsItemProps,
} from '@telegram-apps/telegram-ui/dist/components/Blocks/InlineButtons/components/InlineButtonsItem/InlineButtonsItem';
import { FC } from 'react';

import styles from './index.module.css';

export type CircleButtonProps = Omit<
  InlineButtonsItemProps,
  'className' | 'mode'
>;

export const CircleButton: FC<CircleButtonProps> = ({ ...rest }) => {
  return <InlineButtonsItem className={styles.button} mode="gray" {...rest} />;
};
