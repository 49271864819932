import { getCollectionsList, getGamesListCollections } from '@/api/games';
import { PageTitle } from '@/components/PageTitle';
import { Collection } from '@/shared/prisma';
import { CollectionsListResponse } from '@/shared/services/http/game';
import { GetScheduleListResponse } from '@/shared/services/http/schedule';
import { Select, Spinner, Text } from '@telegram-apps/telegram-ui';
import { useEffect, useState } from 'react';
import { StickyProvider, StickyScrollUp } from 'react-stickup';
import { CheckedScheduleCollectionList } from './components/CheckedScheduleCollectionList';
import _ from 'lodash';

import styles from './index.module.css';

export const OrganizerOnCityGamesCollectionPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [collections, setCollections] = useState<
    CollectionsListResponse | undefined
  >();
  const [games, setGames] = useState<GetScheduleListResponse | undefined>();
  const [selectedCollection, setSelectedCollection] = useState<Collection>();

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      setIsError(false);
      try {
        const collectionsList = await getCollectionsList();
        setCollections(collectionsList);
        setSelectedCollection(collectionsList?.[0]);
      } catch (error) {
        setIsError(true);
        console.error('Cannot load collections', error);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setIsError(false);
      try {
        const gamesList = await getGamesListCollections();
        setGames(_.reverse(_.sortBy(gamesList, (v) => v.createdAt)));
      } catch (error) {
        setIsError(true);
        console.error('Cannot games collections', error);
      }
    };
    getData();
  }, [selectedCollection]);

  const handleSelectCollection = (value: string) => {
    if (!collections) {
      return;
    }
    const found = collections.find((v) => v.id === value);
    if (found) {
      setSelectedCollection(found);
    } else {
      setSelectedCollection(undefined);
    }
  };

  const checked =
    games && selectedCollection
      ? games
          .filter(
            (v) =>
              v.Collections.findIndex((f) => f.id === selectedCollection.id) !==
              -1
          )
          .map((v) => v.id)
      : [];

  return (
    <StickyProvider>
      <StickyScrollUp style={{ zIndex: 1 }}>
        <div className={styles.padding}>
          <PageTitle title="Распределение игр по подборкам" />
          <div className={styles.content}>
            {isLoading && (
              <>
                <Spinner size="s" className={styles.loading} />
              </>
            )}
            {isError && (
              <>
                <div className={styles.emptyWrapper}>
                  <Text className={styles.empty}>
                    Не удалось загрузить расписание игр, попробуйте зайти позже
                  </Text>
                </div>
              </>
            )}
            {collections !== undefined && (
              <>
                <br />
                <Select
                  header="Select"
                  onChange={(e) => handleSelectCollection(e.target.value)}
                >
                  {collections.map((collection, idx) => (
                    <option key={idx} value={collection.id}>
                      {collection.title}
                    </option>
                  ))}
                </Select>
              </>
            )}
          </div>
        </div>
      </StickyScrollUp>
      {collections !== undefined && games && selectedCollection && (
        <div style={{ opacity: !isLoading ? 1 : 0.5 }}>
          <CheckedScheduleCollectionList
            collection={selectedCollection}
            games={games}
            checked={checked}
          />
        </div>
      )}
    </StickyProvider>
  );
};
