import { initUtils } from '@telegram-apps/sdk';
import { Button, Modal, Placeholder, Text } from '@telegram-apps/telegram-ui';

import { Space } from '@/components/Space';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { BiPlus } from 'react-icons/bi';

import styles from './index.module.css';

type Props = {
  item: ScheduleGame;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const CalendarModal: React.FC<Props> = ({
  item,
  isOpen,
  onOpenChange,
}) => {
  const { title, linkSlug } = item;

  const closeModal = () => {
    onOpenChange(false);
  };

  const handleOpenLink = () => {
    const utils = initUtils();
    // TODO: Конфигурировать хост
    utils.openLink(`https://tg.kogdaquiz.ru/game-calendar-ics/${linkSlug}/`, { tryBrowser: true });
    // window.open(`/game-calendar-ics/${linkSlug}/`);
    closeModal();
  };

  return (
    <Modal open={isOpen} onOpenChange={onOpenChange} className={styles.modal}>
      <Placeholder description={title} header="Добавить игру календарь" />
      <Space direction="vertical" className={styles.padding}>
        <Text className={styles.text}>
          Для добавления игры в календарь, скачайте файл и откройте его
          приложением Календарь
        </Text>
        <Button
          mode="gray"
          size="l"
          className={styles.button}
          before={<BiPlus size={24} />}
          onClick={handleOpenLink}
        >
          Добавить
        </Button>
      </Space>
    </Modal>
  );
};
