import { GameCreate } from '@/shared/services/http/organizer';
import { create } from 'zustand';

export type GameData = Partial<GameCreate>;

type GameStore = {
  gameData: GameData | undefined;
  setGameData: (gameData: GameData) => Promise<void>;
  clearGameData: () => Promise<void>;
  updateField: (key: keyof GameData, value: unknown) => Promise<void>;
};

const useGameStore = create<GameStore>((set) => ({
  gameData: undefined,
  setGameData: async (gameData: GameData) => {
    set((state) => {
      return { ...state, gameData };
    });
  },
  clearGameData: async () => {
    set((state) => {
      return { ...state, gameData: undefined };
    });
  },
  updateField: async (key: keyof GameData, value: unknown) => {
    set((state) => {
      if (!state.gameData) {
        return state;
      }
      let valueSet = value;
      if (typeof valueSet === 'string' && !valueSet.trim()) {
        valueSet = null;
      }
      state.gameData = {
        ...state.gameData,
        [key]: value,
      };
      return { ...state };
    });
  },
}));

export default useGameStore;
