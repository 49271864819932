import { ScheduleGame } from '@/shared/services/http/schedule';
import { FC } from 'react';

type Props = {
  scheduleGames: ScheduleGame[];
};

export const ScheduleGamesInPromo: FC<Props> = ({ scheduleGames }) => {
  console.log({ scheduleGames });
  return null;
};
