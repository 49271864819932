type Props = {
  url: string;
  height: string;
  width: string;
};

export const CoverImage: React.FC<Props> = ({ url, height, width }) => {
  return (
    <div
      style={{
        maxHeight: height,
        maxWidth: width,
        height,
        width,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
      }}
    >
      <div
        style={{
          top: 0,
          left: 0,
          right: 0,
          height,
          width,
          filter: 'blur(50px)',
          backgroundImage: `url(${url})`,
          position: 'absolute',
          transform: 'scale(1.2)',
        }}
      />
      <div style={{ position: 'relative' }}>
        <img
          alt="Обложка"
          src={url}
          style={{
            display: 'block',
            objectFit: 'cover',
            maxHeight: height,
            maxWidth: width,
            height,
            width,
          }}
        />
      </div>
    </div>
  );
};
