import { getCities } from '@/api/city';
import { GetCitiesResponse } from '@/shared/services/http/city';
import { create } from 'zustand';

type CitiesStore = {
  cities: GetCitiesResponse | undefined;
  refreshCities: () => Promise<void>;
};

const useCitiesStore = create<CitiesStore>((set) => ({
  cities: undefined,
  refreshCities: async () => {
    const cities = await getCities();
    set(() => ({ cities }));
  },
}));

export default useCitiesStore;
