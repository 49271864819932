import { routes } from '@/routes';
import { Tabbar } from '@telegram-apps/telegram-ui';
import { FC, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import styles from './index.module.css';

type Props = {
  isShowOrganizerTab: boolean;
  onMenuClick?: () => void;
};

export const MenuBar: FC<Props> = ({ isShowOrganizerTab, onMenuClick }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const routesRender = useMemo(() => {
    return routes
      .filter((v) => v.isInMenuBar)
      .filter((v) => {
        if (v.isOrganizerTab) {
          return isShowOrganizerTab;
        }
        return true;
      });
  }, [routes, isShowOrganizerTab]);

  const handleNavigation = (to: string) => {
    navigate(to, { replace: true });
    onMenuClick?.();
  };

  return (
    <Tabbar className={styles.tabbar}>
      {routesRender.map((route, idx) => (
        <Tabbar.Item
          key={idx}
          text={route.title}
          onClick={() => handleNavigation(route.path)}
          selected={location.pathname === route.path}
        >
          {route.icon}
        </Tabbar.Item>
      ))}
    </Tabbar>
  );
};
