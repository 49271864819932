import { initUtils } from '@telegram-apps/sdk-react';
import { Button, Text } from '@telegram-apps/telegram-ui';
import { useState } from 'react';

import { Space } from '@/components/Space';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { ifNullThenUndefined } from '@/shared/utils/ifNullThenUndefined';
import { BiCalendarPlus, BiShare } from 'react-icons/bi';
import { CalendarModal } from './components/CalendarModal';
import { ShareModal } from './components/ShareModal';

import styles from './index.module.css';

type Props = {
  item: ScheduleGame;
  isRegistrationAvailable?: boolean;
};

export const Actions: React.FC<Props> = ({
  item,
  isRegistrationAvailable = true,
}) => {
  const { title, imageUrl, linkSlug, cost, registerLink } = item;

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

  const shareUrl = `${window.location.origin}/game/${linkSlug}/`;

  const handleSharePress = () => {
    setIsShareModalOpen(true);
  };

  const handleCalendarPress = () => {
    setIsCalendarModalOpen(true);
  };

  const handleShareModalOpenChange = (isOpen: boolean) => {
    setIsShareModalOpen(isOpen);
  };

  const handleCalendarModalOpenChange = (isOpen: boolean) => {
    setIsCalendarModalOpen(isOpen);
  };

  const handleRegisterClick = () => {
    if (!registerLink) {
      return;
    }
    const utils = initUtils();
    utils.openLink(registerLink, { tryBrowser: true });
    // onClick={() => window.open(registerLink)}
  };

  return (
    <>
      <div className={styles.shareBlock}>
        <Space className={styles.share}>
          {registerLink && isRegistrationAvailable && (
            <Button mode="bezeled" size="s" onClick={handleRegisterClick}>
              Регистрация
            </Button>
          )}
          <Button mode="outline" size="s" onClick={handleCalendarPress}>
            <BiCalendarPlus size={20} className={styles.icon} />
          </Button>
          <Button mode="outline" size="s" onClick={handleSharePress}>
            <BiShare size={20} className={styles.icon} />
          </Button>
        </Space>
        {cost && (
          <Text weight="3" className={styles.cost}>
            {cost} ₽
          </Text>
        )}
      </div>
      <ShareModal
        title={title}
        shareUrl={shareUrl}
        imageUrl={ifNullThenUndefined(imageUrl)}
        isOpen={isShareModalOpen}
        onOpenChange={handleShareModalOpenChange}
      />
      <CalendarModal
        item={item}
        isOpen={isCalendarModalOpen}
        onOpenChange={handleCalendarModalOpenChange}
      />
    </>
  );
};
