import { getLinkPreview } from '@/api/linkPreview';
import { GetLinkPreviewResponse } from '@/shared/services/http/linkPreview';
import { Spinner } from '@telegram-apps/telegram-ui';
import { useDebounce } from 'ahooks';
import { FC, useEffect, useMemo, useState } from 'react';
import { BiError, BiInfoCircle } from 'react-icons/bi';
import { isValidHttpUrl } from '../../utils';
import { MediaInfo } from '../MediaInfo';
import { MediaView } from '../MediaView';

import styles from './index.module.css';

type Cache = {
  text: string;
  response: GetLinkPreviewResponse;
}[];

type Props = {
  text?: string | null;
  onPreview?: (value: GetLinkPreviewResponse) => void;
  mediaTypes?: string[];
  className?: string;
  hintText?: string;
  errorText?: string;
  isShowUpdating?: boolean;
};

export const LinkPreview: FC<Props> = ({
  text,
  onPreview,
  mediaTypes,
  className,
  hintText,
  errorText,
  isShowUpdating = true,
}) => {
  const [cache, setCache] = useState<Cache>([]);
  const [data, setData] = useState<GetLinkPreviewResponse>(false);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isError, setIsError] = useState(false);

  const textDebounced = useDebounce(text);

  useEffect(() => {
    if (text === '') {
      setIsUpdating(false);
      setIsError(false);
      setData(false);
      onPreview?.(false);
      return;
    }
    setIsUpdating(true);
  }, [text]);

  useEffect(() => {
    const getData = async () => {
      if (!textDebounced) {
        setIsUpdating(false);
        setIsError(false);
        setData(false);
        onPreview?.(false);
        return;
      }
      if (!isValidHttpUrl(textDebounced)) {
        setIsUpdating(false);
        setIsError(true);
        setData(false);
        onPreview?.(false);
        return;
      }
      setIsUpdating(true);
      try {
        let response = cache.find(
          (v) => v.text === textDebounced.toLowerCase().trim()
        )?.response;
        if (response === undefined) {
          response = await getLinkPreview({
            text: textDebounced,
          });
          setCache([
            ...cache,
            { text: textDebounced.toLowerCase().trim(), response },
          ]);
        }
        if (
          typeof response === 'object' &&
          mediaTypes &&
          mediaTypes.length > 0 &&
          mediaTypes.indexOf(response.mediaType) === -1
        ) {
          setIsError(true);
          setData(false);
          onPreview?.(false);
          return;
        }
        setIsError(!response);
        setData(response);
        onPreview?.(response);
      } finally {
        setIsUpdating(false);
      }
    };
    getData();
  }, [textDebounced]);

  // https://tg.kogdaquiz.ru/game/6cc/
  // https://disk.yandex.ru/i/WbLkQ6gGB5wcZQ
  // https://vk.com/azbuki43?w=wall-175464436_14497
  // https://sun9-17.userapi.com/impg/6kXRxxJHpAaFmZS2uscmNwc8MNmI0Mcf0z8giA/0ndQO9Khgnk.jpg?size=1280x720&quality=95&sign=823ab3ae26c41404797242f28187169a&type=album

  const mediaPreviewComponent = useMemo(() => {
    if (isUpdating && isShowUpdating) {
      return (
        <MediaInfo icon={<Spinner size="s" className={styles.spinner} />} />
      );
    }
    if (isError) {
      return (
        <MediaInfo
          icon={<BiError className={styles.spinner} />}
          text={errorText}
        />
      );
    }
    if (data === false || isUpdating) {
      return (
        <MediaInfo
          icon={<BiInfoCircle className={styles.spinner} />}
          text={hintText}
        />
      );
    }
    return <MediaView media={data} />;
  }, [data, isUpdating, isError, hintText, errorText]);

  return <div className={className}>{mediaPreviewComponent}</div>;
};
