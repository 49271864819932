import useOrganizerStore from '@/stores/useOrganizerStore';
import { FC } from 'react';
import { MenuBar } from '../MenuBar';

import styles from './index.module.css';

type Props = {
  content: React.ReactNode;
  isShowMenuBar?: boolean;
};

export const Page: FC<Props> = ({ content, isShowMenuBar = true }) => {
  const listOrganizer = useOrganizerStore((state) => state.listOrganizer);
  const handleOnMenuClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <div className={styles.content}>{content}</div>
      {isShowMenuBar && (
        <MenuBar
          isShowOrganizerTab={listOrganizer.length > 0}
          onMenuClick={handleOnMenuClick}
        />
      )}
    </>
  );
};
