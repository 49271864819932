import { getInitDataRaw } from '@/helpers/auth';
import { AuthType } from '@/shared/services/http/auth';
import {
  AddToFavoritesParams,
  AddToFavoritesResponse,
  RemoveFromFavoritesParams,
  RemoveFromFavoritesResponse,
} from '@/shared/services/http/user';
import request from 'umi-request';
import { API } from '../../const/env';

export const addToFavorites = async (params: AddToFavoritesParams) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  const { gameId } = params;
  return await request<AddToFavoritesResponse>(
    `${API}/user/favorites/${gameId}`,
    {
      method: 'GET',
      headers: {
        authorization,
      },
    }
  );
};

export const removeFromFavorites = async (
  params: RemoveFromFavoritesParams
) => {
  const initDataRaw = getInitDataRaw();
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  const { gameId } = params;
  return await request<RemoveFromFavoritesResponse>(
    `${API}/user/favorites/${gameId}`,
    {
      method: 'DELETE',
      headers: {
        authorization,
      },
    }
  );
};
