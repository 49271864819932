import quiz_placeholder from '@/assets/quiz-placeholder.jpg';
import { Space } from '@/components/Space';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { Card, Headline, Subheadline } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { CoverImage } from './components/CoverImage';

import styles from './index.module.css';

type Props = {
  scheduleGame: ScheduleGame;
  onClick?: () => void;
};

export const GameLineItem: FC<Props> = ({ scheduleGame, onClick }) => {
  const { title, scheduledAt, imageUrl } = scheduleGame;
  return (
    <Card className={styles.wrapper} onClick={onClick}>
      <Space direction="vertical">
        <CoverImage
          url={imageUrl ?? quiz_placeholder}
          width="256px"
          height="100px"
        />
        <Space size="small" direction="vertical" className={styles.content}>
          <Headline className={styles.title}>{title}</Headline>
          <Subheadline className={styles.dateTime}>
            {getHumanDateTime(scheduledAt)}
          </Subheadline>
        </Space>
      </Space>
    </Card>
  );
};
