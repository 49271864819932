import { getScheduleItem } from '@/api/schedule';
import { QuizError } from '@/pages/ScheduleGamePage/QuizError';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { List, Spinner } from '@telegram-apps/telegram-ui';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GamesLine } from '../../Content/components/ScheduleGamesInCollections/components/GamesLine';
import { GamesInCollection } from '../../Content/components/ScheduleGamesInCollections/types';
import { ScheduleItem } from '../../Content/components/ScheduleItem';

import styles from './index.module.css';

export const ViewGamePage = () => {
  const location = useLocation();

  const locationLinkSlug = location.state.linkSlug as string;
  const locationGamesInCollection = location.state
    .gamesInCollection as GamesInCollection;

  const [linkSlug, setLinkSlug] = useState(locationLinkSlug);

  const [quiz, setQuiz] = useState<ScheduleGame>();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!linkSlug) {
      setIsError(true);
      return;
    }
    const getData = async () => {
      setIsError(false);
      setIsLoading(true);
      try {
        const r = await getScheduleItem({ linkSlug });
        setQuiz(r);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    getData();
  }, [linkSlug]);

  if (isLoading) {
    return (
      <div className={styles.padding}>
        <List className={styles.centerPadding}>
          <Spinner size="l" />
        </List>
      </div>
    );
  }

  if (isError || !quiz) {
    return (
      <div className={styles.padding}>
        <List className={styles.centerPadding}>
          <QuizError />
        </List>
      </div>
    );
  }

  const handleOnGameClick = (newLinkSlug: string) => {
    setLinkSlug(newLinkSlug);
  };

  return (
    <div className={styles.padding}>
      <ScheduleItem item={quiz} />
      <br />
      <br />
      <GamesLine
        scheduleGames={locationGamesInCollection.list.filter(
          (v) => v.linkSlug !== linkSlug
        )}
        onGameClick={(newLinkSlug) => handleOnGameClick(newLinkSlug)}
      />
      <br />
      <br />
    </div>
  );
};
