import { ScheduleGame } from '@/shared/services/http/schedule';
import { FC } from 'react';
import { GameLineItem } from '../GamesLineItem';

import styles from './index.module.css';

type Props = {
  scheduleGames: ScheduleGame[];
  onGameClick?: (linkSlug: string) => void;
};

export const GamesLine: FC<Props> = ({ scheduleGames, onGameClick }) => {
  return (
    <div className={styles.wrapper}>
      {scheduleGames.map((game, idx) => (
        <GameLineItem
          key={idx}
          scheduleGame={game}
          onClick={onGameClick ? () => onGameClick(game.linkSlug) : undefined}
        />
      ))}
    </div>
  );
};
